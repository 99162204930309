import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Clutch from "../components/clutch"
import ATG_work from "../images/allthatgrow.png"
import Work_women from "../images/work-women.jpg"
import Work__mydolabs from "../images/mydalabs.png"
import Work__Buns from "../images/buns.png"
import Work__PD from "../images/petdoor.png"
import Work__RD from "../images/retrodays.png"
import {Helmet} from "react-helmet/es/Helmet";

export default () => (
  <Layout className="work-page">
    <Helmet>
      <title>
        Contact Us | Get in Touch with Cueblocks </title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
      <meta charSet="utf-8"/>
      <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
      <meta name="description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta property="og:site_name" content="Cueblocks"/>
      <meta property="fb:app_id" content="289086684439915"/>
      <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
      <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
      <meta property="og:description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
      <meta name="twitter:site" content="@cueblocks"/>
      <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
    </Helmet>
    <section className=" workpage-topsection wk_top">
      <div className="container">
        <h2 className="heading_main">our work</h2>
        <div className="ser_sec_row_lt pd_right_40 ">
         
          <div className="ser_sec_row_lt_text">
            <p>
              Evolving in and with the digital space over the past 15 years, we’ve walked alongside our clients on their road to growth & success. From developing
               high-performance eCommerce websites to building winning brand identities through aesthetically pleasing design as well as statistic-backed digital marketing
                & search engine optimization strategies, we’ve pretty much done it all and everything else in between. Here’s a sneak peek into some of our prolific work. 
            </p>
          </div>
        </div>
        <div className="ser_sec_row_rt pd_left_40">
          <div className="ser_se">
            <h2 className="heading_main">
              If it’s eCommerce, we walk the mile with you!
            </h2>
          </div>
        </div>

        <div className="other-works">
          <div className="ser_sec_row_lt left pd_right_40 pd_top_40">
            <a href="https://www.allthatgrows.in/" target="_blank" rel="noopener noreferrer">
              <img src={ATG_work} alt="CueBlocks" className="img-responsive" />
              <h6>AllThatGrows / Shopify Development</h6>
            </a>
          </div>
          <div className="ser_sec_row_lt right pd_left_40">
            <a href="https://womanswork.com/" target="_blank" rel="noopener noreferrer">
              <img
                src={Work_women}
                alt="Cue Blocks"
                className="img-responsive"
              />
              <h6>WomansWork / M2 Speed Optimization</h6>
            </a>
          </div>
        </div>

        <div className="other-works other-works-sec2">
          <div className="ser_sec_row_lt left">
            <a href="https://mydaolabs.com/" target="_blank" rel="noopener noreferrer">
              <img
                src={Work__mydolabs}
                alt="Cue Blocks"
                className="img-responsive"
              />
              <h6>MyDAOLabs / Shopify Development</h6>
            </a>
          </div>
          <div className="ser_sec_row_lt right">
            <a href="https://gunsberg.in/" target="_blank" rel="noopener noreferrer">
              <img
                src={Work__Buns}
                alt="Cue Blocks"
                className="img-responsive"
              />
              <h6>Gunsberg / Packaging Design</h6>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className=" workpage-topsection workpage-midsection">
      <div className="container">
        <div className="work-block">
          <div className="ser_sec_row_lt ">
            <h2 className="heading_main">
              Drop your requirements in our inbox and we'll get in touch with you.
            </h2>
          </div>
          <div className="ser_sec_row_rt ">
            <p>Kickstart or jazz-up your brand with us. </p>
            <Link to="/contact" className="btn main_cta">
              Let’s Work Together
            </Link>
          </div>
        </div>
        <div className="other-works">
          <div className="ser_sec_row_lt left">
            <a href="https://www.petdoors.com/" target="_blank" rel="noopener noreferrer">
              <img src={Work__PD} alt="Cue Blocks" className="img-responsive" />
              <h6>PetDoors / Magento Development</h6>
            </a>
          </div>
          <div className="ser_sec_row_lt right">
            <a href="https://shop.retrodays.in/" target="_blank" rel="noopener noreferrer">
              <img src={Work__RD} alt="Cue Blocks" className="img-responsive" />
              <h6>Retrodays / Shopify Development</h6>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className=" other-projects-block">
      <div className="container">
        <div className="other-projects-block-top">
          <div className="ser_sec_row_lt pd_right_40 ">
            <h2 className="heading_main">A Few Other Ventures Undertaken</h2>
          </div>
          <div className="ser_sec_row_lt pd_left_40">
            <p>
              That was just a short snippet of our work. It’s not just the big websites that take the cake every time. We love the little things we do for our
               clients just as much. Take a look at some of our other proud work.
            </p>
          </div>
        </div>
        <div className="ser_sec_row_rt ">
          <ul className="our-project-links ">
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                ryda.com
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                cln skin care{" "}
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                cloud 10 beauty
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                its hot.com{" "}
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                betty and biddy
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                clix capital
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                mollybrown{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <Clutch />
  </Layout>
)
